export default [
  {
    path: '/users',
    name: 'users',
    component: () => import('@/views/users/UserList.vue'),
    meta: {
      title: 'Users - DealMart',
      requiresAuth: true,
      pageTitle: 'Users',
      breadcrumb: [
        {
          text: 'Users',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },

  {
    path: '/users/edit/:id',
    name: 'edit-user',
    meta: {
      title: 'Edit User - DealMart',
      requiresAuth: true,
      pageTitle: 'Users',
      breadcrumb: [
        {
          text: 'Users',
          to: '/users',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/users/view/:id',
    name: 'show-user-customer',
    component: () => import('@/views/users/control-users/ViewUser.vue'),
    meta: {
      requiresAuth: true,
      title: 'View User - DealMart',
      action: 'read',
      resource: 'customer_users',
      pageTitle: 'Users',
      breadcrumb: [
        {
          text: 'Users',
          to: '/users',
        },
        {
          text: 'Details',
          active: true,
        },
      ],
    },
  },

]
