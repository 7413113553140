export default {
  namespaced: true,
  state: {
    countdownEnded: false,
  },
  mutations: {
    SET_COUNTDOWN_ENDED(state, value) {
      state.countdownEnded = value
    },
  },
  actions: {
    setCountdownEnded({ commit }) {
      commit('SET_COUNTDOWN_ENDED', true)
    },
  },
  getters: {
    isCountdownEnded: state => state.countdownEnded,
  },
}
