export default [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Home.vue'),
    meta: {
      title: 'Dashboard - DealMart',
      requiresAuth: true,

    },
  },
  {
    path: '/draw/:id',
    name: 'draw',
    component: () => import('@/views/draw.vue'),
    meta: {
      requiresAuth: true,
      action: 'Read',
      layout: 'full',

    },
  },
  {
    path: '/winners',
    name: 'winners',
    component: () => import('@/views/winners/Winners.vue'),
    meta: {
      requiresAuth: true,
      action: 'Read',
      pageTitle: 'Winners',
      breadcrumb: [
        {
          text: 'Winners',
          active: true,
        },
      ],
    },
  },

  {
    path: '/faqs',
    name: 'faqs',
    component: () => import('@/views/faqs/FAQS.vue'),
    meta: {
      title: 'FAQs - DealMart',
      requiresAuth: true,
      action: 'browes',
      resource: 'FAQs',
      pageTitle: 'FAQS',
      breadcrumb: [
        {
          text: 'FAQS',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/faqs/add',
    name: 'add-faq',
    component: () => import('@/views/faqs/control-faqs/AddEditFAQ.vue'),
    meta: {
      title: 'Add FAQS - DealMart',
      requiresAuth: false,
      action: 'create',
      resource: 'offers',
      pageTitle: 'FAQS',
      breadcrumb: [
        {
          text: 'FAQS',
          to: '/faqs',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/faqs/edit/:id',
    name: 'edit-faq',
    component: () => import('@/views/faqs/control-faqs/AddEditFAQ.vue'),
    meta: {
      title: 'Edit FAQS - DealMart',
      requiresAuth: false,
      pageTitle: 'FAQS',
      breadcrumb: [
        {
          text: 'FAQS',
          to: '/faqs',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/offers',
    name: 'offers',
    component: () => import('@/views/offers/Offers.vue'),
    meta: {
      title: 'Offers - DealMart',
      requiresAuth: true,
      action: 'browes',
      resource: 'offers',
      pageTitle: 'Offers',
      breadcrumb: [
        {
          text: 'Offers',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/promoCode',
    name: 'promoCode',
    component: () => import('@/views/promoCodes/PromoCode.vue'),
    meta: {
      title: 'Promo Codes - DealMart',
      requiresAuth: true,
      action: 'browes',
      resource: 'offers',
      pageTitle: 'Promo Codes',
      breadcrumb: [
        {
          text: 'Promo Codes',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/countries',
    name: 'countries',
    component: () => import('@/views/countries/Countries.vue'),
    meta: {
      title: 'Countries - DealMart',
      requiresAuth: true,
      action: 'browes',
      resource: 'countries',
    },
  },
  {
    path: '/countries/add',
    name: 'add-country',
    component: () => import('@/views/countries/control-countries/AddEditCountries.vue'),
    meta: {
      title: 'Add Country - DealMart',
      requiresAuth: false,
      action: 'create',
      resource: 'offers',
      pageTitle: 'countries',
      breadcrumb: [
        {
          text: 'countries',
          to: '/countries',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/countries/edit/:id',
    name: 'edit-country',
    component: () => import('@/views/countries/control-countries/AddEditCountries.vue'),
    meta: {
      title: 'Edit Country - DealMart',
      requiresAuth: false,
      pageTitle: 'countries',
      breadcrumb: [
        {
          text: 'Countries',
          to: '/Countries',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },

  {
    path: '/shipping-companies',
    name: 'shipping-companies',
    component: () => import('@/views/shipping/shipping-companies/ShippingCompanies.vue'),
    meta: {
      title: 'Shipping companies - DealMart',
      requiresAuth: true,
      action: 'browes',
      pageTitle: 'Shipping companies',
      breadcrumb: [
        {
          text: 'Shipping companies',
          to: '/shipping-companies',

        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/shipping-companies/add',
    name: 'add-shipping-companies',
    component: () => import('@/views/shipping/shipping-companies/control-shipping-companies/AddEditShippingCompanies.vue'),
    meta: {
      title: 'Add Shipping Zone - DealMart',
      requiresAuth: true,
      action: 'create',
      pageTitle: 'Shipping companies',
      breadcrumb: [
        {
          text: 'Shipping companies',
          to: '/shipping-companies',

        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/shipping-companies/edit/:id',
    name: 'edit-shipping-companies',
    component: () => import('@/views/shipping/shipping-companies/control-shipping-companies/AddEditShippingCompanies.vue'),
    meta: {
      title: 'Edit Shipping Zone - DealMart',
      requiresAuth: true,
      action: 'update',
      pageTitle: 'Shipping companies',
      breadcrumb: [
        {
          text: 'Shipping companies',
          to: '/shipping-companies',

        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/shipping-zones',
    name: 'shipping-zones',
    component: () => import('@/views/shipping/shipping-zones/ShippingZones.vue'),
    meta: {
      title: 'Shipping Zones - DealMart',
      requiresAuth: true,
      action: 'browes',
      pageTitle: 'Shipping Zones',
      breadcrumb: [
        {
          text: 'Shipping Zones',
          to: '/shipping-zones',

        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/shipping-zones/add',
    name: 'add-shipping-zones',
    component: () => import('@/views/shipping/shipping-zones/control-shipping-zones/AddEditShippingZones.vue'),
    meta: {
      title: 'Add Shipping Zone - DealMart',
      requiresAuth: true,
      action: 'create',
      pageTitle: 'Shipping Zones',
      breadcrumb: [
        {
          text: 'Shipping Zones',
          to: '/shipping-zones',

        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/shipping-zones/edit/:id',
    name: 'edit-shipping-zones',
    component: () => import('@/views/shipping/shipping-zones/control-shipping-zones/AddEditShippingZones.vue'),
    meta: {
      title: 'Edit Shipping Zone - DealMart',
      requiresAuth: true,
      action: 'update',
      pageTitle: 'Shipping Zones',
      breadcrumb: [
        {
          text: 'Shipping Zones',
          to: '/shipping-zones',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },

  {
    path: '/offers/add',
    name: 'add-offer',
    component: () => import('@/views/offers/control-offer/AddEditoffer.vue'),
    meta: {
      title: 'Add Offer - DealMart',
      requiresAuth: true,
      action: 'create',
      resource: 'offers',
      pageTitle: 'Offers',
      breadcrumb: [
        {
          text: 'Offers',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/offers/edit/:id',
    name: 'edit-offer',
    component: () => import('@/views/offers/control-offer/AddEditoffer.vue'),
    meta: {
      title: 'Edit Offer - DealMart',
      requiresAuth: true,
      action: 'update',
      resource: 'offers',
      pageTitle: 'Offers',
      breadcrumb: [
        {
          text: 'Offers',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/promoCode/add',
    name: 'add-promoCode',
    component: () => import('@/views/promoCodes/control-promoCode/AddEditpromoCode.vue'),
    meta: {
      title: 'Add promoCode - DealMart',
      requiresAuth: true,
      action: 'create',
      resource: 'offers',
      pageTitle: 'promoCode',
      breadcrumb: [
        {
          text: 'Promo Codes',
          to: '/promoCode',

        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/promoCode/edit/:id',
    name: 'edit-promoCode',
    component: () => import('@/views/promoCodes/control-promoCode/AddEditpromoCode.vue'),
    meta: {
      title: 'Edit PromoCode - DealMart',
      requiresAuth: true,

      pageTitle: 'PromoCode',
      breadcrumb: [
        {
          text: 'Promo Codes',
          to: '/promoCode',

        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/products',
    name: 'products',
    component: () => import('@/views/products/Products.vue'),
    meta: {
      title: 'Products - DealMart',
      requiresAuth: false,
      action: 'browes',
      resource: 'offers',
      pageTitle: 'Products',
      breadcrumb: [
        {
          text: 'Products',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/products/add',
    name: 'add-product',
    component: () => import('@/views/products/control-products/AddEditProduct.vue'),
    meta: {
      title: 'Add Product - DealMart',
      requiresAuth: false,
      action: 'create',
      resource: 'offers',
      pageTitle: 'Products',
      breadcrumb: [
        {
          text: 'Products',
          to: '/products',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/products/edit/:id',
    name: 'edit-product',
    component: () => import('@/views/products/control-products/AddEditProduct.vue'),
    meta: {
      title: 'Edit Product - DealMart',
      requiresAuth: false,
      action: 'update',
      resource: 'offers',
      pageTitle: 'Products',
      breadcrumb: [
        {
          text: 'Products',
          to: '/products',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: () => import('@/views/notifications/Notifications.vue'),
    meta: {
      title: 'Notifications - DealMart',
      requiresAuth: true,
      action: 'browse',
      pageTitle: 'Notifications',
      breadcrumb: [
        {
          text: 'Notifications',
          active: true,
        },
      ],
    },
  },
  {
    path: '/areas',
    name: 'areas',
    component: () => import('@/views/areas/Areas.vue'),
    meta: {
      title: 'Areas - DealMart',
      requiresAuth: true,
      action: 'browes',
      resource: 'areas',
      pageTitle: 'Areas',
      breadcrumb: [
        {
          text: 'Places',
        },
        {
          text: 'Areas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/governments',
    name: 'governments',
    component: () => import('@/views/governments/Governments.vue'),
    meta: {
      title: 'Governorates - DealMart',
      requiresAuth: true,
      action: 'browes',
      resource: 'governments',
      pageTitle: 'Governorates',
      breadcrumb: [

        {
          text: 'Governorates',
          active: true,
        },
      ],
    },
  },
  {
    path: '/governments/add',
    name: 'add-governments',
    component: () => import('@/views/governments/control-governments/AddEditGovernments.vue'),
    meta: {
      title: 'Add Governorates - DealMart',
      requiresAuth: false,
      action: 'create',
      resource: 'offers',
      pageTitle: 'Governorates',
      breadcrumb: [
        {
          text: 'Governorates',
          to: '/governments',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/governments/edit/:id',
    name: 'edit-governments',
    component: () => import('@/views/governments/control-governments/AddEditGovernments.vue'),
    meta: {
      title: 'Edit Governorates - DealMart',
      requiresAuth: false,
      action: 'update',
      resource: 'offers',
      pageTitle: 'Governorates',
      breadcrumb: [
        {
          text: 'Governorates',
          to: '/governments',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/states',
    name: 'states',
    component: () => import('@/views/states/States.vue'),
    meta: {
      title: 'States - DealMart',
      requiresAuth: true,
      action: 'browes',
      resource: 'states',
      pageTitle: 'States',
      breadcrumb: [
        {
          text: 'Places',
        },
        {
          text: 'States',
          active: true,
        },
      ],
    },
  },
  {
    path: '/user-profile',
    name: 'user-profile',
    component: () => import('@/views/user-profile/UserProfile.vue'),
    meta: {
      title: 'User Settings - DealMart',
      requiresAuth: true,
      action: 'browes',
      resource: 'dashboard',
      pageTitle: 'Account Settings',
      breadcrumb: [
        {
          text: 'Account Settings',
          active: true,
        },
      ],
    },
  },
  {
    path: '/configs',
    name: 'configs',
    component: () => import('@/views/configs/Configs.vue'),
    meta: {
      title: 'Configrations - DealMart',
      requiresAuth: true,
      action: 'browes',
      resource: 'configs',
      pageTitle: 'Configs',
      breadcrumb: [
        {
          text: 'Configs',
          active: true,
        },
      ],
    },
  },
  // {
  //   path: '/moduel',
  //   name: 'moduel',
  //   component: () => import('@/views/modules/Module.vue'),
  //   meta: {
  //     title: 'Module Settings',
  //     requiresAuth: true,
  //     pageTitle: 'Module Settings',
  //     action: 'browes',
  //     resource: 'dashboard',
  //   },
  // },
  {
    path: '/howItWorks',
    name: 'howItWorks',
    component: () => import('@/views/howItWorks/HowItWorks.vue'),
    meta: {
      title: 'How It Works - DealMart',
      requiresAuth: true,
      action: 'browes',
      resource: 'configs',
      pageTitle: 'How It Works',
      breadcrumb: [
        {
          text: 'Sections',
          active: true,
        },
      ],
    },
  },
  {
    path: '/zerocashVoucher',
    name: 'zerocashVoucher',
    component: () => import('@/views/zerocashVoucher/zerocashVoucher.vue'),
    meta: {
      title: 'Zerocash Voucher - DealMart',
      requiresAuth: true,
      action: 'browes',
      resource: 'configs',
      pageTitle: 'ZeroCash Voucher',
      breadcrumb: [
        {
          text: 'Sections',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/views/settings/Settings.vue'),
    meta: {
      title: 'Settings - DealMart',
      requiresAuth: true,
      action: 'browes',
      resource: 'configs',
      pageTitle: 'Configration Settings',
      breadcrumb: [
        {
          text: 'Settings',
          active: true,
        },
      ],
    },
    children: [
      {
        path: ':group',
        name: 'general-settings',
        props: true,
        component: () => import('@/views/settings/GeneralConfigSetting.vue'),
        meta: {
          title: 'Settings',
          requiresAuth: true,
          pageTitle: 'Configration Settings',
          breadcrumb: [
            {
              text: 'Settings',
              active: true,
            },
          ],
        },
      },
    ],
  },
  {
    path: '/orders',
    name: 'orders',
    component: () => import('@/views/orders/Orders.vue'),
    meta: {
      title: 'Orders',
      requiresAuth: true,
      pageTitle: 'Orders',
      action: 'browes',
      resource: 'configs',
    },
  },
  {
    path: '/orders/view/:id',
    name: 'show-order',
    component: () => import('@/views/orders/ViewOrder.vue'),
    meta: {
      title: 'view order - DealMart',
      requiresAuth: true,
      pageTitle: 'Orders',
      breadcrumb: [
        {
          text: 'Orders',
          to: '/orders',
        },
        {
          text: 'view',
          active: true,
        },
      ],
    },
  },
  {
    path: '/banners',
    name: 'banners',
    component: () => import('@/views/banners/Banners.vue'),
    meta: {
      title: 'Banners',
      requiresAuth: true,
      pageTitle: 'Banners',
      action: 'browes',
      resource: 'banners',
    },
  },
  {
    path: '/sliders',
    name: 'sliders',
    component: () => import('@/views/sliders/Sliders.vue'),
    meta: {
      title: 'Sliders',
      requiresAuth: true,
      pageTitle: 'Sliders',
      action: 'browes',
      resource: 'sliders',
    },
  },
  {
    path: '/sliders/arrange',
    name: 'arrange-sliders',
    component: () => import('@/views/sliders/arrange/arrange.vue'),
    meta: {
      title: 'Arrange',
      requiresAuth: true,
      pageTitle: 'Arrange Sliders',
      action: 'browes',
    },
  },
  {
    path: '/sliders/add',
    name: 'add-slider',
    component: () => import('@/views/sliders/control-sliders/AddEditSliders.vue'),
    meta: {
      title: 'Add Slider - DealMart',
      requiresAuth: false,
      action: 'create',
      pageTitle: 'Sliders',
      breadcrumb: [
        {
          text: 'Sliders',
          to: '/sliders',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/sliders/edit/:id',
    name: 'edit-slider',
    component: () => import('@/views/sliders/control-sliders/AddEditSliders.vue'),
    meta: {
      title: 'Edit Slider - DealMart',
      requiresAuth: false,
      action: 'update',
      pageTitle: 'Sliders',
      breadcrumb: [
        {
          text: 'Sliders',
          to: '/sliders',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/arrange',
    name: 'arrange',
    component: () => import('@/views/campaigns/arrange/arrange.vue'),
    meta: {
      title: 'Arrange',
      requiresAuth: true,
      pageTitle: 'Arrange',
      action: 'browes',
    },
  },
  {
    path: '/campaign-types',
    name: 'campaign-types',
    component: () => import('@/views/campaigns/campaign-types/CampaignTypes.vue'),
    meta: {
      title: 'Campaign Types - DealMart',
      requiresAuth: true,
      action: 'browes',
      pageTitle: 'Campaign Types',
      breadcrumb: [
        {
          text: 'Campaign Types',
          to: '/campaign-types',

        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/campaign-types/add',
    name: 'add-campaign-types',
    component: () => import('@/views/campaigns/campaign-types/control-campaign-types/AddEditCampaignTypes.vue'),
    meta: {
      title: 'Add Campaign Type - DealMart',
      requiresAuth: true,
      action: 'create',
      pageTitle: 'Campaign Types',
      breadcrumb: [
        {
          text: 'Campaign Types',
          to: '/campaign-types',

        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/campaign-types/edit/:id',
    name: 'edit-campaign-types',
    component: () => import('@/views/campaigns/campaign-types/control-campaign-types/AddEditCampaignTypes.vue'),
    meta: {
      title: 'Edit Campaign Types - DealMart',
      requiresAuth: true,
      action: 'update',
      pageTitle: 'Campaign Types',
      breadcrumb: [
        {
          text: 'Campaign Types',
          to: '/campaign-types',

        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/campaigns',
    name: 'campaigns',
    component: () => import('@/views/campaigns/normal-campaigns/Campaigns.vue'),
    meta: {
      title: 'Campaigns',
      requiresAuth: true,
      pageTitle: 'Campaigns',
      action: 'browes',
    },
  },
  {
    path: '/campaigns/add',
    name: 'add-campaign',
    component: () => import('@/views/campaigns/normal-campaigns/control-campaigns/AddEditCampaigns.vue'),
    meta: {
      title: 'Add Campaign - DealMart',
      requiresAuth: false,
      action: 'create',
      pageTitle: 'Campaigns',
      breadcrumb: [
        {
          text: 'Campaigns',
          to: '/campaigns',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/campaigns/edit/:id',
    name: 'edit-campaign',
    component: () => import('@/views/campaigns/normal-campaigns/control-campaigns/AddEditCampaigns.vue'),
    meta: {
      title: 'Edit Campaigns - DealMart',
      requiresAuth: false,
      action: 'update',
      pageTitle: 'Campaigns',
      breadcrumb: [
        {
          text: 'Campaigns',
          to: '/campaigns',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/campaigns/view/:id',
    name: 'show-campaign',
    component: () => import('@/views/campaigns/normal-campaigns/control-campaigns/ViewCampaign.vue'),
    meta: {
      title: 'view Campaigns - DealMart',
      requiresAuth: false,
      action: 'update',
      pageTitle: 'Campaigns',
      breadcrumb: [
        {
          text: 'Campaigns',
          to: '/campaigns',
        },
        {
          text: 'view',
          active: true,
        },
      ],
    },
  },
  {
    path: '/campaigns/sections/:id',
    name: 'campaign-sections',
    component: () => import('@/views/campaigns/normal-campaigns/sections/sections.vue'),
    meta: {
      title: ' Sections - DealMart',
      requiresAuth: true,
      action: 'update',
      pageTitle: 'Campaign Sections',
      breadcrumb: [
        {
          text: 'Campaign',
          to: '/campaigns',

        },
        {
          text: 'Sections',
          active: true,
        },
      ],
    },
  },
  {
    path: '/campaigns/addSection/:id',
    name: 'add-campaign-section',
    component: () => import('@/views/campaigns/normal-campaigns/sections/control-section/AddEditCampainSection.vue'),
    meta: {
      title: 'Add Section - DealMart',
      requiresAuth: true,
      action: 'add',
      pageTitle: 'Campaign Section',
      breadcrumb: route => ([
        {
          text: 'Sections',
          to: {
            name: 'campaign-sections',
            params: { id: route.params.id },
          },

        },
        {
          text: 'Add',
          active: true,
        },
      ]),
    },
  },
  {
    path: '/campaigns/edit-section/:id',
    name: 'edit-campaign-section',
    component: () => import('@/views/campaigns/normal-campaigns/sections/control-section/AddEditCampainSection.vue'),
    meta: {
      title: 'Edit Section - DealMart',
      requiresAuth: true,
      action: 'update',
      pageTitle: 'Campaign Section',
      breadcrumb: route => ([
        {
          text: 'Sections',
          to: {
            name: 'campaign-sections',
            params: { id: route.params.id },
          },

        },
        {
          text: 'Edit',
          active: true,
        },
      ]),
    },
  },
  {
    path: '/action-campaigns',
    name: 'action-campaigns',
    component: () => import('@/views/campaigns/action-campaign/ActionCampaigns.vue'),
    meta: {
      title: 'Action Campaigns',
      requiresAuth: true,
      pageTitle: 'Action Campaigns',
      action: 'browes',
    },
  },
  {
    path: '/action-campaigns/add',
    name: 'add-action-campaign',
    component: () => import('@/views/campaigns/action-campaign/control-action-campaigns/AddEditActionCampaigns.vue'),
    meta: {
      title: 'Add Action Campaign - DealMart',
      requiresAuth: false,
      action: 'create',
      pageTitle: 'Action Campaigns',
      breadcrumb: [
        {
          text: 'Action Campaigns',
          to: '/action-campaigns',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/action-campaigns/edit/:id',
    name: 'edit-action-campaign',
    component: () => import('@/views/campaigns/action-campaign/control-action-campaigns/AddEditActionCampaigns.vue'),
    meta: {
      title: 'Edit Action Campaigns - DealMart',
      requiresAuth: false,
      action: 'update',
      pageTitle: 'Action Campaigns',
      breadcrumb: [
        {
          text: 'Action Campaigns',
          to: '/action-campaigns',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/action-campaigns/view/:id',
    name: 'show-action-campaign',
    component: () => import('@/views/campaigns/action-campaign/control-action-campaigns/ViewActionCampaign.vue'),
    meta: {
      title: 'view Action Campaigns - DealMart',
      requiresAuth: false,
      action: 'update',
      pageTitle: 'Action Campaigns',
      breadcrumb: [
        {
          text: 'Action Campaigns',
          to: '/action-campaigns',
        },
        {
          text: 'view',
          active: true,
        },
      ],
    },
  },
  {
    path: '/campaigns/Tiers/:id',
    name: 'campaign-tiers',
    component: () => import('@/views/campaigns/action-campaign/tiers/tiers.vue'),
    meta: {
      title: ' Tiers - DealMart',
      requiresAuth: true,
      action: 'update',
      pageTitle: 'Campaign Tiers',
      breadcrumb: [
        {
          text: 'Action Campaign',
          to: '/action-campaigns',

        },
        {
          text: 'Tiers',
          active: true,
        },
      ],
    },
  },
  {
    path: '/action-campaigns/add-tiern/:id',
    name: 'add-campaign-tier',
    component: () => import('@/views/campaigns/action-campaign/tiers/control-tiers/AddEditCampainTiers.vue'),
    meta: {
      title: 'Add Tiers - DealMart',
      requiresAuth: true,
      action: 'add',
      pageTitle: 'Campaign Tiers',
      breadcrumb: [
        {
          text: 'Tiers',
          to: {
            name: 'campaign-tiers',

          },

        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/action-campaigns/edit-tier/:id',
    name: 'edit-campaign-tier',
    component: () => import('@/views/campaigns/action-campaign/tiers/control-tiers/AddEditCampainTiers.vue'),
    meta: {
      title: 'Edit Tier - DealMart',
      requiresAuth: true,
      action: 'update',
      pageTitle: 'Campaign Tiers',
      breadcrumb: [
        {
          text: 'Tiers',
          to: {
            name: 'campaign-tiers',
          },
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/offline-campaigns',
    name: 'offline-campaigns',
    component: () => import('@/views/campaigns/offline-campaigns/OfflineCampaigns.vue'),
    meta: {
      title: 'Offline Campaigns',
      requiresAuth: true,
      pageTitle: 'Offline Campaigns',
      action: 'browes',
    },
  },
  {
    path: '/offline-campaigns/add',
    name: 'add-offline-campaigns',
    component: () => import('@/views/campaigns/offline-campaigns/control-offline-campaigns/AddEditOfflineCampaigns.vue'),
    meta: {
      title: 'Add Offline Campaign - DealMart',
      requiresAuth: false,
      action: 'create',
      pageTitle: 'Offline Campaigns',
      breadcrumb: [
        {
          text: 'Offline Campaigns',
          to: '/offline-campaigns',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/offline-campaigns/edit/:id',
    name: 'edit-offline-campaigns',
    component: () => import('@/views/campaigns/offline-campaigns/control-offline-campaigns/AddEditOfflineCampaigns.vue'),
    meta: {
      title: 'Edit Offline Campaigns - DealMart',
      requiresAuth: false,
      action: 'update',
      pageTitle: 'Offline Campaigns',
      breadcrumb: [
        {
          text: 'Offline Campaigns',
          to: '/offline-campaigns',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/offline-campaigns/editworks/:id',
    name: 'edit-offline-campaign-works',
    component: () => import('@/views/campaigns/normal-campaigns/sections/control-section/AddEditCampainSection.vue'),
    meta: {
      title: 'Edit Section - DealMart',
      requiresAuth: true,
      action: 'update',
      pageTitle: 'Offline Campaigns Section',
      breadcrumb: [
        {
          text: 'Offline Campaigns',
          to: '/offline-campaigns',

        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/offline-campaigns/view/:id',
    name: 'show-offline-campaigns',
    component: () => import('@/views/campaigns/offline-campaigns/control-offline-campaigns/ViewOfflineCampaign.vue'),
    meta: {
      title: 'view Offline Campaigns - DealMart',
      requiresAuth: false,
      action: 'update',
      pageTitle: 'Offline Campaigns',
      breadcrumb: [
        {
          text: 'Offline Campaigns',
          to: '/offline-campaigns',
        },
        {
          text: 'view',
          active: true,
        },
      ],
    },
  },
  {
    path: '/watch-campaigns',
    name: 'watch-campaigns',
    component: () => import('@/views/campaigns/watch-campaigns/WatchCampaigns.vue'),
    meta: {
      title: 'Watch Campaigns',
      requiresAuth: true,
      pageTitle: 'Watch Campaigns',
      action: 'browes',
    },
  },
  {
    path: '/watch-campaigns/add',
    name: 'add-watch-campaigns',
    component: () => import('@/views/campaigns/watch-campaigns/control-watch-campaigns/AddEditWatchCampaigns.vue'),
    meta: {
      title: 'Add Watch Campaign - DealMart',
      requiresAuth: false,
      action: 'create',
      pageTitle: 'Watch Campaigns',
      breadcrumb: [
        {
          text: 'Watch Campaigns',
          to: '/watch-campaigns',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/watch-campaigns/edit/:id',
    name: 'edit-watch-campaigns',
    component: () => import('@/views/campaigns/watch-campaigns/control-watch-campaigns/AddEditWatchCampaigns.vue'),
    meta: {
      title: 'Edit Watch Campaigns - DealMart',
      requiresAuth: false,
      action: 'update',
      pageTitle: 'Watch Campaigns',
      breadcrumb: [
        {
          text: 'Watch Campaigns',
          to: '/watch-campaigns',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/watch-campaigns/editworks/:id',
    name: 'edit-watch-campaign-works',
    component: () => import('@/views/campaigns/normal-campaigns/sections/control-section/AddEditCampainSection.vue'),
    meta: {
      title: 'Edit Section - DealMart',
      requiresAuth: true,
      action: 'update',
      pageTitle: 'watch Campaigns Section',
      breadcrumb: [
        {
          text: 'Watch Campaigns',
          to: '/watch-campaigns',

        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/watch-campaigns/view/:id',
    name: 'show-watch-campaigns',
    component: () => import('@/views/campaigns/watch-campaigns/control-watch-campaigns/ViewWatchCampaign.vue'),
    meta: {
      title: 'view Watch Campaigns - DealMart',
      requiresAuth: false,
      action: 'update',
      pageTitle: 'Watch Campaigns',
      breadcrumb: [
        {
          text: 'Watch Campaigns',
          to: '/watch-campaigns',
        },
        {
          text: 'view',
          active: true,
        },
      ],
    },
  },

  {
    path: '/flash-campaigns',
    name: 'flash-campaigns',
    component: () => import('@/views/campaigns/flash-campaigns/FlashCampaign.vue'),
    meta: {
      title: 'Flash Sale - DealMart',
      requiresAuth: true,
      action: 'browes',
      pageTitle: 'Flash Sale',
      breadcrumb: [
        {
          text: 'Flash Sale',
          to: '/flash-campaigns',

        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/flash-campaigns/editworks/:id',
    name: 'edit-flash-campaign-works',
    component: () => import('@/views/campaigns/normal-campaigns/sections/control-section/AddEditCampainSection.vue'),
    meta: {
      title: 'Edit Section - DealMart',
      requiresAuth: true,
      action: 'update',
      pageTitle: 'flash Campaigns Section',
      breadcrumb: [
        {
          text: 'Flash Campaigns',
          to: '/flash-campaigns',

        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/flash-campaigns/view/:id',
    name: 'show-flash-campaign',
    component: () => import('@/views/campaigns/flash-campaigns/control-flash-campaign/ViewFlashCampaign.vue'),
    meta: {
      title: 'view Flash Campaigns - DealMart',
      requiresAuth: false,
      pageTitle: 'Flash Campaigns',
      breadcrumb: [
        {
          text: 'Flash Campaigns',
          to: '/flash-campaigns',
        },
        {
          text: 'view',
          active: true,
        },
      ],
    },
  },
  {
    path: '/flash-campaigns/add',
    name: 'add-flash-campaigns',
    component: () => import('@/views/campaigns/flash-campaigns/control-flash-campaign/AddEditFlashCampaign.vue'),
    meta: {
      title: 'Add Flash Sale - DealMart',
      requiresAuth: true,
      action: 'create',
      pageTitle: 'Flash Sale',
      breadcrumb: [
        {
          text: 'Flash Sale',
          to: '/flash-campaigns',

        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/flash-campaigns/edit',
    name: 'edit-flash-campaigns',
    component: () => import('@/views/campaigns/flash-campaigns/control-flash-campaign/AddEditFlashCampaign.vue'),
    meta: {
      title: 'Edit Flash Sale - DealMart',
      requiresAuth: true,
      action: 'update',
      pageTitle: 'Flash Sale',
      breadcrumb: [
        {
          text: 'Flash Sale',
          to: '/flash-campaigns',

        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/product-ads',
    name: 'product-ads',
    component: () => import('@/views/product-ads/ProductAds.vue'),
    meta: {
      title: 'Product Ads',
      requiresAuth: true,
      pageTitle: 'Product Ads',
      action: 'browes',
      breadcrumb: [
        {
          text: 'Product Ads',
          active: true,
        },
      ],
    },
  },
  {
    path: '/product-ads/add',
    name: 'add-product-ad',
    component: () => import('@/views/product-ads/control-product-ads/AddEditProductAds.vue'),
    meta: {
      title: 'Add Product Ad - DealMart',
      requiresAuth: false,
      action: 'create',
      pageTitle: 'Product Ads',
      breadcrumb: [
        {
          text: 'Product Ads',
          to: '/product-ads',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/product-ads/edit/:id',
    name: 'edit-product-ad',
    component: () => import('@/views/product-ads/control-product-ads/AddEditProductAds.vue'),
    meta: {
      title: 'Edit Product Ad  - DealMart',
      requiresAuth: false,
      action: 'update',
      pageTitle: 'Product Ads ',
      breadcrumb: [
        {
          text: 'Product Ads ',
          to: '/product-ads',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },

  {
    path: '/tickets',
    name: 'tickets',
    component: () => import('@/views/ticket/Tickets.vue'),
    meta: {
      title: 'Tickets',
      requiresAuth: true,
      pageTitle: 'Tickets',
      action: 'browes',
    },

  },
  {
    path: '/vouchers',
    name: 'vouchers',
    component: () => import('@/views/vouchers/Vouchers.vue'),
    meta: {
      title: 'Vouchers',
      requiresAuth: true,
      pageTitle: 'Vouchers',
      action: 'browes',
    },

  },
  {
    path: '/tickets/view/:id',
    name: 'view-ticket',
    component: () => import('@/views/ticket/ViewTicket.vue'),
    meta: {
      title: 'Tickets',
      requiresAuth: true,
      pageTitle: 'Tickets',
      breadcrumb: [
        {
          text: 'Tickets',
          to: '/tickets',
        },
        {
          text: 'view',
          active: true,
        },
      ],
    },

  },
  {
    path: '/spin-gifts',
    name: 'spin-gifts',
    component: () => import('@/views/spin-gifts/SpinGifts.vue'),
    meta: {
      title: 'Spin Gifts',
      requiresAuth: true,
      pageTitle: 'Spin Gifts',
      action: 'browes',
    },
  },
  {
    path: '/spin-gifts/add',
    name: 'add-spin-gifts',
    component: () => import('@/views/spin-gifts/control-spin-gift/AddEditSpinGift.vue'),
    meta: {
      title: 'Add Spin Gifts - DealMart',
      requiresAuth: false,
      action: 'create',
      pageTitle: 'Spin Gifts',
      breadcrumb: [
        {
          text: 'Spin Gifts',
          to: '/spin-gifts',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/spin-gifts/edit/:id',
    name: 'edit-spin-gifts',
    component: () => import('@/views/spin-gifts/control-spin-gift/AddEditSpinGift.vue'),
    meta: {
      title: 'Edit Spin Gifts - DealMart',
      requiresAuth: false,
      action: 'update',
      pageTitle: 'Spin Gifts',
      breadcrumb: [
        {
          text: 'spin-gifts',
          to: '/spin-gifts',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/gifts',
    name: 'gifts',
    component: () => import('@/views/gifts/Gifts.vue'),
    meta: {
      title: 'Gifts',
      requiresAuth: true,
      pageTitle: 'Gifts',
      action: 'browes',
    },
  },
  {
    path: '/gifts/add',
    name: 'add-gifts',
    component: () => import('@/views/gifts/control-gift/AddEditGift.vue'),
    meta: {
      title: 'Add Gifts - DealMart',
      requiresAuth: false,
      action: 'create',
      pageTitle: 'Gifts',
      breadcrumb: [
        {
          text: 'Gifts',
          to: '/gifts',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/gifts/edit/:id',
    name: 'edit-gifts',
    component: () => import('@/views/gifts/control-gift/AddEditGift.vue'),
    meta: {
      title: 'Edit Gifts - DealMart',
      requiresAuth: false,
      action: 'update',
      pageTitle: 'Gifts',
      breadcrumb: [
        {
          text: 'gifts',
          to: '/gifts',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },

  {
    path: '/social-contacts',
    name: 'social-contacts',
    component: () => import('@/views/social-contacts/SocialContacts.vue'),
    meta: {
      title: 'Social Contacts',
      requiresAuth: true,
      pageTitle: 'Social Contacts',
      action: 'browes',
      breadcrumb: [
        {
          text: 'Social Contacts',
          active: true,
        },

      ],
    },
  },
  {
    path: '/app-version',
    name: 'app-version',
    component: () => import('@/views/app-version/AppVersion.vue'),
    meta: {
      title: 'App Version',
      requiresAuth: true,
      pageTitle: 'App Version',
      action: 'browes',
      breadcrumb: [
        {
          text: 'App Version',
          active: true,
        },

      ],
    },
  },
  {
    path: '/app-data',
    name: 'app-data',
    component: () => import('@/views/app-data/AppData.vue'),
    meta: {
      title: 'App Data',
      requiresAuth: true,
      pageTitle: 'App Data',
      action: 'browes',
      breadcrumb: [
        {
          text: 'App Data',
          active: true,
        },

      ],
    },
  },
  {
    path: '/user-messages',
    name: 'user-messages',
    component: () => import('@/views/user-messages/UserMessages.vue'),
    meta: {
      title: 'User Messages',
      requiresAuth: true,
      pageTitle: 'User Messages',
      action: 'browes',
      breadcrumb: [
        {
          text: 'User Messages',
          active: true,
        },

      ],
    },
  },
  {
    path: '/partners',
    name: 'partners',
    component: () => import('@/views/partners/Partners.vue'),
    meta: {
      title: 'Partners - DealMart',
      requiresAuth: true,
      pageTitle: 'Partners',
      action: 'browes',
      breadcrumb: [
        {
          text: 'Partners',
          active: true,
        },

      ],
    },
  },

  {
    path: '/partners/add',
    name: 'add-partner',
    component: () => import('@/views/partners/control-partners/AddEditPartners.vue'),
    meta: {
      title: 'Add Partner - DealMart',
      requiresAuth: false,
      action: 'create',
      pageTitle: 'Partners',
      breadcrumb: [
        {
          text: 'Partners',
          to: '/partners',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/partners/edit/:id',
    name: 'edit-partner',
    component: () => import('@/views/partners/control-partners/AddEditPartners.vue'),
    meta: {
      title: 'Edit Partners - DealMart',
      requiresAuth: false,
      action: 'update',
      pageTitle: 'Partners',
      breadcrumb: [
        {
          text: 'Partners',
          to: '/partners',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/user-messages/view/:id',
    name: 'view-user-msg',
    component: () => import('@/views/user-messages/ViewUserMsg.vue'),
    meta: {
      title: 'User Messages',
      requiresAuth: true,
      pageTitle: 'User Messages',
      breadcrumb: [
        {
          text: 'User Messages',
          to: '/user-messages',
        },
        {
          text: 'view',
          active: true,
        },
      ],
    },

  },
  {
    path: '/partners-branches',
    name: 'partners-branches',
    component: () => import('@/views/partners/branches/Branches.vue'),
    meta: {
      title: 'Branches - DealMart',
      requiresAuth: true,
      pageTitle: "Partner's Branches",
      action: 'browes',
      breadcrumb: [
        {
          text: 'branches',
          active: true,
        },

      ],
    },
  },

  {
    path: '/partners-branches/add',
    name: 'add-branch',
    component: () => import('@/views/partners/branches/control-branches/AddEditBranches.vue'),
    meta: {
      title: 'Add Branch - DealMart',
      requiresAuth: false,
      action: 'create',
      pageTitle: "Partner's Branches",
      breadcrumb: [
        {
          text: 'Branches',
          to: '/partners-branches',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/partners-branches/edit/:id',
    name: 'edit-branch',
    component: () => import('@/views/partners/branches/control-branches/AddEditBranches.vue'),
    meta: {
      title: 'Edit Branches - DealMart',
      requiresAuth: false,
      action: 'update',
      pageTitle: "Partner's Branches",
      breadcrumb: [
        {
          text: 'branches',
          to: '/partners-branches',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/partners-employees',
    name: 'partners-employees',
    component: () => import('@/views/partners/employees/Employees.vue'),
    meta: {
      title: 'Employees - DealMart',
      requiresAuth: true,
      pageTitle: "Partner's Employees",
      action: 'browes',
      breadcrumb: [
        {
          text: 'Employees',
          active: true,
        },

      ],
    },
  },

  {
    path: '/partners-employees/add',
    name: 'add-employee',
    component: () => import('@/views/partners/employees/control-employees/AddEditEmployees.vue'),
    meta: {
      title: 'Add Employee - DealMart',
      requiresAuth: false,
      action: 'create',
      pageTitle: "Partner's Employees",
      breadcrumb: [
        {
          text: 'Employees',
          to: '/partners-employees',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/partners-employees/edit/:id',
    name: 'edit-employee',
    component: () => import('@/views/partners/employees/control-employees/AddEditEmployees.vue'),
    meta: {
      title: 'Edit Employees - DealMart',
      requiresAuth: false,
      action: 'update',
      pageTitle: "Partner's Employees",
      breadcrumb: [
        {
          text: 'Employees',
          to: '/partners-employees',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },

  {
    path: '/news',
    name: 'news',
    component: () => import('@/views/news/News.vue'),
    meta: {
      title: 'News - DealMart',
      requiresAuth: true,
      pageTitle: 'News',
      action: 'browes',
      breadcrumb: [
        {
          text: 'News',
          active: true,
        },

      ],
    },
  },

  {
    path: '/news/add',
    name: 'add-news',
    component: () => import('@/views/news/control-news/AddEditNews.vue'),
    meta: {
      title: 'Add Partner - DealMart',
      requiresAuth: false,
      action: 'create',
      pageTitle: 'News',
      breadcrumb: [
        {
          text: 'News',
          to: '/news',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/news/edit/:id',
    name: 'edit-news',
    component: () => import('@/views/news/control-news/AddEditNews.vue'),
    meta: {
      title: 'Edit News - DealMart',
      requiresAuth: false,
      action: 'update',
      pageTitle: 'News',
      breadcrumb: [
        {
          text: 'News',
          to: '/news',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },

  {
    path: '/contact-categories',
    name: 'contact-categories',
    component: () => import('@/views/contact-categories/ContactCategories.vue'),
    meta: {
      title: 'Contact Categories',
      requiresAuth: true,
      pageTitle: 'Contact Categories',
      action: 'browes',
      breadcrumb: [
        {
          text: 'Contact Categories',
          active: true,
        },

      ],
    },
  },
  {
    path: '/contact-categories/add',
    name: 'add-contact-categories',
    component: () => import('@/views/contact-categories/control-contact-categories/AddEditContactCategories.vue'),
    meta: {
      title: 'Add Contact Category - DealMart',
      requiresAuth: false,
      action: 'create',
      pageTitle: 'Contact Category ',
      breadcrumb: [
        {
          text: 'Contact Categories',
          to: '/contact-categories',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/contact-categories/edit',
    name: 'edit-contact-categories',
    component: () => import('@/views/contact-categories/control-contact-categories/AddEditContactCategories.vue'),
    meta: {
      title: 'Add Contact Category - DealMart',
      requiresAuth: false,
      action: 'update',
      pageTitle: 'Contact Category ',
      breadcrumb: [
        {
          text: 'Contact Categories',
          to: '/contact-categories',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/winners-photos',
    name: 'winners-photos',
    component: () => import('@/views/winners-photos/WinnersPhotos.vue'),
    meta: {
      title: 'Winners Photos',
      requiresAuth: true,
      pageTitle: 'Winners Photos',
      action: 'browes',
    },
  },
]
