// store/selectedProducts.js
export default {
  namespaced: true,

  state: {
    selectedRows: [], // Original selected rows
    selectedRowsCopy: [], // Copy of selected rows for editing
    currentPage: 1, // Current page
    itemsPerPage: 5, // Items per page
  },

  mutations: {
    setSelectedRows(state, selectedRows) {
      state.selectedRows = selectedRows
    },

    setSelectedRowsCopy(state, selectedRowsCopy) {
      state.selectedRowsCopy = selectedRowsCopy
    },

    setCurrentPage(state, page) {
      state.currentPage = page
    },
    addSelectedRows(state, selectedRows) {
      selectedRows.forEach(newRow => {
        // Check if the row already exists based on ID
        const existingRow = state.selectedRows.find(row => row.id === newRow.id)
        if (existingRow) {
          // If the row exists, update the number of tickets
          existingRow.num_of_tickets = newRow.num_of_tickets
        } else {
          // If the row doesn't exist, add it to the selectedRows array
          state.selectedRows.push(newRow)
        }
      })
    },
    updateProductNumOfTickets(state, { selectedRowId, numOfTickets }) {
      // Check if the row already exists based on ID
      const existingRow = state.selectedRows.find(row => row.id === selectedRowId)
      if (existingRow) {
        // If the row exists, update the number of tickets
        existingRow.num_of_tickets = numOfTickets
      }
    },

    removeSelectedRow(state, removedRow) {
      state.selectedRows = state.selectedRows.filter(row => row.id !== removedRow.id)
    },

    clearSelectedRows(state) {
      state.selectedRows = []
      state.selectedRowsCopy = []
      state.currentPage = 1
    },
  },

  actions: {
    updateSelectedRows({ commit, state }, selectedRows) {
      // Merge newly selected rows with existing ones
      const mergedRows = [...state.selectedRows, ...selectedRows]

      // Deduplicate by id
      const uniqueRows = Array.from(new Set(mergedRows.map(row => row.id))).map(id => mergedRows.find(row => row.id === id))

      commit('setSelectedRows', uniqueRows)
    },

    addSelectedRow({ commit }, selectedRow) {
      commit('addSelectedRows', [selectedRow])
    },
    updateProductNumOfTickets({ commit }, { selectedRowId, numOfTickets }) {
      commit('updateProductNumOfTickets', { selectedRowId, numOfTickets })
    },
    saveSelectedRowCopy({ commit, state }) {
      // Save a copy of selected rows for editing
      commit('setSelectedRowsCopy', [...state.selectedRows])
    },

    removeSelectedRow({ commit }, removedRow) {
      commit('removeSelectedRow', removedRow)
    },

    setCurrentPage({ commit }, page) {
      commit('setCurrentPage', page)
    },

    clearAllSelectedRows({ commit, state }) {
      // Revert to the original selected rows
      commit('setSelectedRows', [...state.selectedRowsCopy])
      commit('setCurrentPage', 1) // Reset to the first page
    },
  },

  getters: {
    paginatedSelectedRows: state => {
      const startIndex = (state.currentPage - 1) * state.itemsPerPage
      const endIndex = startIndex + state.itemsPerPage
      return state.selectedRows.slice(startIndex, endIndex)
    },
    getSelectedRows: state => state.selectedRows,
    getCurrentPage: state => state.currentPage,
    totalPages: state => Math.ceil(state.selectedRows.length / state.itemsPerPage),
  },
}
